
export default {
  data() {
    return {
      editView: false,
      profileData: {
        firstName: 'Abhinab',
        lastName: 'Rajopadhyaya',
        phoneNumber: '9849304819',
        emailAddress: 'abhinab@gritfeat.com'
      }
    };
  },
  methods: {
    toggleEditView(isEdit) {
      this.editView = isEdit;
    }
  }
};
